import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import { bookieForm, editBookieForm } from "../../schemas/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useUser } from "../../hooks";
import { useUsers } from "../../contexts/UsersContext";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const bookiesWithBirthdate = ['winamax'];
const bookiesWithEmail = ['tonybet'];

const PasswordBadge = styled.div`
  background-color: #2A3C29;
  color: #758B78;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
  margin-top: 4px;
  display: inline-block;
`;

const BookieModal = ({ show, onHide, bookie, type, email, onSuccess }) => {
  const { addBookie, getBookieCredentials } = useUser();
  const { mutate } = useUsers();
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [existingCredentials, setExistingCredentials] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCredentials = async () => {
      if (type === "edit" && show) {
        try {
          const credentials = await getBookieCredentials(email, bookie);
          setExistingCredentials(credentials);
        } catch (err) {
          setError(err.message || "Error fetching credentials");
        }
      }
    };
    fetchCredentials();
  }, [type, show, email, bookie, getBookieCredentials]);

  const handleAddBookie = async values => {
    const { username, password, enable, date, birthdate } = values;
    try {
      await addBookie({ 
        email, 
        bookie, 
        action: type, 
        username, 
        password, 
        enable, 
        date, 
        birthdate 
      });
      mutate();
      if (onSuccess) onSuccess();
      onHide();
    } catch (err) {
      setError(err.message || "Error updating bookie");
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{type === "add" ? t("addBookie") : t("editBookie")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <Formik
          validationSchema={type === 'add' ? bookieForm : editBookieForm}
          initialValues={type === 'add' 
            ? { username: "", password: "", enable: false } 
            : { 
                username: existingCredentials?.username || "", 
                password: "", 
                enable: false 
              }
          }
          enableReinitialize
          onSubmit={async values => handleAddBookie(values)}
        >
          {({
            handleSubmit,
            handleChange,
            touched,
            errors,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Form.Group controlId="username">
                <Form.Label>{t(bookiesWithEmail.includes(bookie) ? "email" : "username")}</Form.Label>
                <Form.Control
                  type={bookiesWithEmail.includes(bookie) ? "email" : "text"}
                  placeholder={t(bookiesWithEmail.includes(bookie) ? "email" : "username")}
                  value={values.username}
                  onChange={handleChange}
                  isValid={touched.username && !errors.username}
                />
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>{t("password")}</Form.Label>
                <Form.Control
                  placeholder={t("password")}
                  value={values.password}
                  onChange={handleChange}
                  isValid={touched.password && !errors.password}
                />
                {type === "edit" && existingCredentials?.password && (
                  <PasswordBadge>
                    {t("currentPassword")}: {existingCredentials.password}
                  </PasswordBadge>
                )}
                <Form.Text style={{ color: "#758B78" }}>
                  {t("passwordSecure")}
                </Form.Text>
              </Form.Group>
              {bookiesWithBirthdate.includes(bookie) && (
                <Form.Group controlId="birthdate">
                  <Form.Label>{t("birthdate")}</Form.Label>
                  <Form.Control
                    type="date"
                    value={values.birthdate}
                    onChange={handleChange}
                    isValid={touched.birthdate && !errors.birthdate}
                  />
                </Form.Group>
              )}
              <Form.Group controlId="enableControl">
                <Form.Label>{t("enable")}</Form.Label>
                <Form.Control
                  as="select"
                  value={values.enableControl}
                  onChange={val => {
                    handleChange(val);
                    if (val.nativeEvent.target.selectedIndex === 1) {
                      setFieldValue("enable", true);
                      setShowDatePicker(false);
                    } else if (val.nativeEvent.target.selectedIndex === 2) {
                      setFieldValue("enable", false);
                      setShowDatePicker(true);
                    } else {
                      setFieldValue("enable", false);
                      setShowDatePicker(false);
                    }
                  }}
                  label="Activar"
                >
                  <option>{t("noEnable")}</option>
                  <option>{t("enableNow")}</option>
                  <option>{t("scheduleEnable")}</option>
                </Form.Control>
              </Form.Group>
              {showDatePicker && (
                <Form.Group controlId="enable">
                  <Form.Label>{t("activationDate")}</Form.Label>
                  <DatePicker
                    className="form-control"
                    selected={startDate}
                    onChange={(date) => {
                      setFieldValue("date", date);
                      setStartDate(date.getTime());
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    minDate={new Date()}
                  />
                </Form.Group>
              )}
              <div className="d-flex justify-content-end mt-3">
                <button type="button" className="btn btn-secondary me-2" onClick={onHide}>
                  {t("cancel")}
                </button>
                <button type="submit" className="btn btn-primary">
                  {type === "add" ? t("add") : t("edit")}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default BookieModal; 