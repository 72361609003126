import React, { useState, useEffect, Suspense, lazy } from "react";
import { checkIfTokenStillValid } from "../../repositories/utils";
import { NavBarMain } from "..";
import { useTranslation } from "react-i18next";
import { PageContainer, Subcontainer } from './BotHome.styled';
import { useUsers } from "../../contexts/UsersContext";
import { useAuth, useBilling } from "../../hooks";

const HomeContent = lazy(() => import('./HomeContent'));

export function BotHome({ history }) {
  const { t } = useTranslation();
  const { userInfo, users } = useUsers();
  const { modifyUser } = useAuth();
  const { getTotalPending, getTotalPaidGross, compareAndSetPendingPayments, getTotal } = useBilling();

  const isAdmin = userInfo?.roles?.includes("admin");
  const [allBoxesCollapsed, setAllBoxesCollapsed] = useState(false);
  const [collapsedBoxes, setCollapsedBoxes] = useState({});

  const toggleUserBox = (userId) => {
    if (allBoxesCollapsed) {
      setAllBoxesCollapsed(false);
      setCollapsedBoxes({
        ...Object.fromEntries(users?.map(u => [u._id.email, true]) || []),
        [userId]: false
      });
    } else {
      setCollapsedBoxes(prev => ({
        ...prev,
        [userId]: !prev[userId]
      }));
    }
  };

  const toggleAllBoxes = () => {
    setAllBoxesCollapsed(prev => !prev);
    setCollapsedBoxes({});
  };

  useEffect(() => {
    async function validateToken() {
      await checkIfTokenStillValid(history);
    }
    validateToken();
  }, [history]);

  return (
    <PageContainer>
      <NavBarMain currentPage="bot" type="dashboard" history={history} />
      <Subcontainer>
        <Suspense>
          {users &&
          <HomeContent
            t={t}
            users={users}
            userInfo={userInfo}
            isAdmin={isAdmin}
            modifyUser={modifyUser}
            getTotalPending={getTotalPending}
            getTotalPaidGross={getTotalPaidGross}
            compareAndSetPendingPayments={compareAndSetPendingPayments}
            getTotal={getTotal}
            allBoxesCollapsed={allBoxesCollapsed}
            collapsedBoxes={collapsedBoxes}
            onToggleUserBox={toggleUserBox}
            onToggleAllBoxes={toggleAllBoxes}
          />
          }
        </Suspense>
      </Subcontainer>
    </PageContainer>
  );
}
