import React, { useState } from "react";
import { Clock, CheckCircle, AlertTriangle } from "lucide-react";
import { useTranslation } from "react-i18next";

import { getSportIcon } from '../../utils/events'
import { getBetStatus } from '../../utils/bets'
import {
  SportIcon
} from './BetCard.styled'

const getPct = arb => ((1 - ((1 / arb.bets[0]?.odds) + (1 / arb.bets[1]?.odds))) * 100).toFixed(2);

export const BetCard = ({ bet, setSettledBet }) => {
  const { t } = useTranslation()
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  function BetRow({ bet }) {
    const { bookie, name, stake, odds, claimable } = bet;

    return (
      <div className="flex w-full">
        <div className="w-[10px] flex-shrink-0 h-auto rounded-l-lg" style={{ backgroundColor: getBetStatus(bet) }}>
        </div>
        <div className="flex items-center justify-between text-sm bg-[#111E12] p-3 rounded-r-lg w-full">
          <div className="flex-1">
            <p className="font-bold text-white capitalize text-lg">{bookie}</p>
            <p className="text-white opacity-70">{name}</p>
          </div>
          <div className="flex items-center space-x-4">
            {claimable && (
              <div className="flex items-center text-yellow-400">
                <AlertTriangle size={20} className="mr-1" />
                <span className="text-sm font-medium">{t("claimMe")}</span>
              </div>
            )}
            <div className="text-right">
              <p className="font-bold text-white text-lg">{Number.isInteger(stake) ? stake : stake.toFixed(2)}€</p>
              <p className="text-white opacity-70">Stake</p>
            </div>
            <div className="text-right">
              <p className="font-bold text-white text-lg">{odds.toFixed(2)}</p>
              <p className="text-white opacity-70">{t("oddsIndividual")}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const handleConfirmArbitrage = async (bet) => {
    setIsUpdating(true)
    // Simulate an API call to update the backend
    await setSettledBet(bet);
    setIsConfirmed(true)
    setIsUpdating(false)
  }

  return (
    <div className="w-full bg-[#1B2D1A] shadow-lg rounded-2xl overflow-hidden">
      <div className="bg-[#1B2D1A] text-white p-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <SportIcon src={getSportIcon(bet.sportName)} className="mr-2"/>
            <h2 className="text-lg font-semibold">{bet.event ? bet.event.name : bet.eventName}</h2>
          </div>
         { getPct(bet) > 0 && <span className="bg-[#E8FC8B] text-[#1B2D1A] px-2 py-1 rounded-full text-md font-semibold">{getPct(bet)}%</span>}
        </div>
        <div className="flex items-center text-md mt-2 text-[#758b77]">
          <Clock className="w-4 h-4 mr-1" />
          <span> { new Date(bet.timestamp).toLocaleString() }</span>
        </div>
      </div>
      <div className="p-4 space-y-4">
        {bet.bets.map(bet => (
          <BetRow key={bet.id} bet={bet} />
        ))}
        {new Date().getTime() - new Date(bet.timestamp).getTime() > 24 * 60 * 60 * 1000 && bet.status !== "SETTLED" && !isConfirmed && (
          <button 
            onClick={() => handleConfirmArbitrage(bet)} 
            disabled={isUpdating}
            className="w-full max-w-[230px] ml-auto bg-[#E8FC8B] font-semibold text-[#1B2D1A] hover:bg-[#E8FC7B] transition-colors p-2 rounded-md"
          >
            {isUpdating ? t("updating") : t("confirmArbitrage")}
          </button>
        )}
        {isConfirmed && (
          <div className="flex items-center justify-center font-semibold text-[#1B2D1A] bg-[#E8FC8B] p-2 rounded-md max-w-[230px]">
            <CheckCircle className="w-5 h-5 mr-2" />
            <span>{t("arbitrageConfirmed")}</span>
          </div>
        )}
      </div>
      {!isNaN(bet.profit) && (
      <div className="bg-[#E8FC8B] p-4 mt-4">
        <div className="w-full flex justify-between items-center text-[#1B2D1A]">
          <span className="font-semibold">{t("totalProfit")}</span>
          <span className="font-bold text-2xl flex items-center">
            {Math.round(bet.profit * 100) / 100}€
          </span>
          </div>
        </div>
      )}
    </div>
  )
}