import React, { useState, useEffect, useMemo, useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Calendar } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion"
import { Form, Table } from "react-bootstrap";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Brush, AreaChart, Area } from 'recharts';
import { ArrowBigUp, Sigma, PiggyBank, Percent, Banknote, XCircleIcon } from 'lucide-react';
import { debounce } from 'lodash';

import { checkIfTokenStillValid } from "../../repositories/utils";
import { NavBarMain, UserBox } from "..";
import {
  PageContainer,
  Subcontainer,
  HomeHeaderContainer,
  UserAlertBadge,
  ChartContainerParent,
  ChartContainer,
  ChartContainerHeader,
  UserContainer,
  TooltipContainer,
  TooltipLabel,
  TooltipItem,
  TooltipItemContainer,
  TooltipIcon,
  TooltipText,
  TooltipValue,
  TooltipSeparator,
  TooltipTotalContainer,
  TooltipTotal,
  TooltipTotalValue,
  Title,
  Value,
  DataMainContainer,
  DataContainer,
  DataContainerHeader,
  DataContainerIcon,
  DataContainerTitle,
  DateRangeContainer,
  DatePickerWrapper,
  DateFilterContainer,
  DateFilterButton,
} from './BotStats.styled';

import { useAuth } from "../../hooks";

// Define colors for the pie chart
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

function formatNumber(number) {
  return new Intl.NumberFormat('es-ES').format(number);
}

// The component itself
export const BotStats = ({ history }) => {
  const [inputText, setInputText] = useState("");
  const [selectedUser, setSelectedUser] = useState("all");
  const [selectedTags, setSelectedTags] = useState([]);
  const [bookieData, setBookieData] = useState();
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [completeChartData, setCompleteChartData] = useState([]);
  const [fullChartData, setFullChartData] = useState([]);
  const [filteredChartData, setFilteredChartData] = useState([]);
  const [activeFilter, setActiveFilter] = useState('all');
  const [averageProfitPerDay, setAverageProfitPerDay] = useState(0);
  const [users, setUsers] = useState(null);

  const { getUserInfo } = useAuth();

  useEffect(() => {
    async function validateToken() {
      await checkIfTokenStillValid(history);
    }
    validateToken();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await getUserInfo(true);
      setUsers(response.users);
    };
    fetchUsers();
  }, [getUserInfo]);

  const filterDataByDateRange = useCallback((data) => {
    if (startDate && endDate) {
      return data.filter(item => {
        // Parse the date parts from the ISO string
        const [year, month, day] = item.date.split('-').map(Number);
        // Create date at start of day in local timezone
        const itemDate = new Date(year, month - 1, day, 0, 0, 0, 0);
        
        // Create comparison dates at start/end of day in local timezone
        const start = new Date(startDate);
        start.setHours(0, 0, 0, 0);
        const end = new Date(endDate);
        end.setHours(23, 59, 59, 999);
        
        return itemDate >= start && itemDate <= end;
      });
    }
    return data;
  }, [startDate, endDate]);

  // Add computeGlobalSuggestedTags function
  const computeGlobalSuggestedTags = () => {
    const tagMap = {};
    users?.forEach(u => {
      if (u?.tags) {
        u.tags.forEach(tag => {
          const tagObj = typeof tag === 'string' ? { name: tag, color: '#ffffff' } : tag;
          if (!tagMap[tagObj.name]) {
            tagMap[tagObj.name] = tagObj;
          }
        });
      }
    });
    return Object.values(tagMap);
  };
  const globalSuggestedTags = computeGlobalSuggestedTags();

  // Helper function for contrast color
  function getContrastColor(hexColor) {
    let color = hexColor.startsWith('#') ? hexColor.slice(1) : hexColor;
    if (color.length === 3) {
      color = color.split('').map(c => c + c).join('');
    }
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 128 ? "#000000" : "#ffffff";
  }

  useEffect(() => {
    if (users) {
      let filteredUsers = users;
      
      // Apply user selection filter
      if (selectedUser !== "all") {
        filteredUsers = filteredUsers.filter(user => user._id.email === selectedUser);
      }

      // Apply tag filter if any tags are selected
      if (selectedTags.length > 0) {
        filteredUsers = filteredUsers.filter(user => {
          const userTags = (user.tags || []).map(tag => 
            typeof tag === 'string' ? tag : tag.name
          );
          return selectedTags.some(tag => userTags.includes(tag));
        });
      }

      const allProfitData = filteredUsers.flatMap(user =>
        user.billing?.profitByDay?.map(day => {
          // Create date using the MongoDB date parts
          const date = new Date(day._id.year, day._id.month - 1, day._id.day);
          return {
            date: `${day._id.year}-${String(day._id.month).padStart(2, '0')}-${String(day._id.day).padStart(2, '0')}`,
            profit: day.profit,
            expenditure: day.expenditure,
            email: day._id.email
          };
        }) || []
      );

      let selectedData = selectedUser === "all" ? allProfitData : allProfitData.filter(data => data.email === selectedUser);

      const groupedData = selectedData.reduce((acc, curr) => {
        if (!acc[curr.date]) {
          acc[curr.date] = { date: curr.date, profit: 0, expenditure: 0 };
        }
        acc[curr.date].profit += curr.profit;
        acc[curr.date].expenditure += curr.expenditure;
        return acc;
      }, {});

      const sortedData = Object.values(groupedData).sort((a, b) => new Date(a.date) - new Date(b.date));

      // Calculate cumulative values
      let cumulativeProfit = 0;
      let cumulativeExpenditure = 0;
      const cumulativeData = sortedData.map(day => {
        cumulativeProfit += day.profit;
        cumulativeExpenditure += day.expenditure;
        return {
          ...day,
          cumulativeProfit: cumulativeProfit,
          cumulativeExpenditure: cumulativeExpenditure
        };
      });

      setCompleteChartData(cumulativeData);
      setFullChartData(cumulativeData);
      setFilteredChartData(cumulativeData);

      // Process bookie data with consistent date handling
      const allBookieData = filteredUsers.flatMap(user =>
        user.billing?.profitByDayAndBookie?.map(entry => ({
          name: entry._id.bookie,
          email: entry._id.email,
          profit: entry.profit,
          pending: entry.pending,
          expenditure: entry.expenditure,
          totalBets: entry.totalBets,
          // Create date using the MongoDB date parts
          date: new Date(entry._id.year, entry._id.month - 1, entry._id.day, 0, 0, 0, 0)
        })) || []
      );

      let filteredBookieData = selectedUser === "all" ? allBookieData : allBookieData.filter(data => data.email === selectedUser);
      
      // Apply date range filter with consistent date handling
      filteredBookieData = filteredBookieData.filter(data => {
        if (!startDate || !endDate) return true;
        const start = new Date(startDate);
        start.setHours(0, 0, 0, 0);
        const end = new Date(endDate);
        end.setHours(23, 59, 59, 999);
        return data.date >= start && data.date <= end;
      });

      // Aggregate bookie data
      const aggregatedBookieData = filteredBookieData.reduce((acc, curr) => {
        const existing = acc.find(item => item.name === curr.name);
        if (existing) {
          existing.profit += curr.profit;
          existing.pending += curr.pending;
          existing.expenditure += curr.expenditure;
          existing.totalBets += curr.totalBets;
        } else {
          acc.push({
            name: curr.name,
            profit: curr.profit,
            pending: curr.pending,
            expenditure: curr.expenditure,
            totalBets: curr.totalBets
          });
        }
        return acc;
      }, []);

      setBookieData(aggregatedBookieData);
    }
  }, [users, selectedUser, startDate, endDate, selectedTags]);

  useEffect(() => {
    if (completeChartData.length > 0) {
      if (startDate && endDate) {
        const filtered = completeChartData.filter(item => {
          // Create dates from the chart data format YYYY-MM-DD
          const [itemYear, itemMonth, itemDay] = item.date.split('-').map(Number);
          const itemDate = new Date(itemYear, itemMonth - 1, itemDay);
          
          // Create comparison dates
          const start = new Date(startDate);
          const end = new Date(endDate);
          
          // Set both dates to noon to avoid timezone issues
          itemDate.setHours(12, 0, 0, 0);
          start.setHours(12, 0, 0, 0);
          end.setHours(12, 0, 0, 0);

          // Compare dates using only the date part
          return itemDate.getTime() >= start.getTime() && itemDate.getTime() <= end.getTime();
        });

        // Recalculate cumulative values for filtered data
        let cumulativeProfit = 0;
        let cumulativeExpenditure = 0;
        const recalculatedData = filtered.map(day => {
          cumulativeProfit += day.profit;
          cumulativeExpenditure += day.expenditure;
          return {
            ...day,
            cumulativeProfit,
            cumulativeExpenditure
          };
        });

        setFullChartData(recalculatedData);
        setFilteredChartData(recalculatedData);
      } else {
        setFullChartData(completeChartData);
        setFilteredChartData(completeChartData);
      }
    }
  }, [completeChartData, startDate, endDate, activeFilter]);

  const totalProfit = useMemo(() => {
    return filteredChartData.reduce((acc, day) => acc + day.profit, 0);
  }, [filteredChartData]);

  const totalBets = useMemo(() => {
    return bookieData?.reduce((acc, bookie) => acc + bookie.totalBets, 0) || 0;
  }, [bookieData]);

  const totalExpenditure = useMemo(() => {
    return filteredChartData.reduce((acc, day) => acc + day.expenditure, 0);
  }, [filteredChartData]);

  const totalYield = useMemo(() => {
    return totalExpenditure !== 0 ? (totalProfit / totalExpenditure) * 100 : 0;
  }, [totalProfit, totalExpenditure]);

  // Update the debouncedUpdateDateRange to handle dates consistently
  const debouncedUpdateDateRange = useCallback(
    debounce((startIndex, endIndex) => {
      if (completeChartData.length > 0) {
        const [startYear, startMonth, startDay] = completeChartData[startIndex].date.split('-').map(Number);
        const [endYear, endMonth, endDay] = completeChartData[endIndex].date.split('-').map(Number);
        
        // Create dates and set to noon to avoid timezone issues
        const newStartDate = new Date(startYear, startMonth - 1, startDay);
        const newEndDate = new Date(endYear, endMonth - 1, endDay);
        newStartDate.setHours(12, 0, 0, 0);
        newEndDate.setHours(12, 0, 0, 0);
        
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        setActiveFilter('custom');
      }
    }, 300),
    [completeChartData]
  );

  // Update the handleBrushChange function
  const handleBrushChange = useCallback((brushArea) => {
    if (brushArea && brushArea.startIndex !== undefined && brushArea.endIndex !== undefined) {
      debouncedUpdateDateRange(brushArea.startIndex, brushArea.endIndex);
    }
  }, [debouncedUpdateDateRange]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <TooltipContainer>
          <TooltipLabel>{new Date(label).toLocaleDateString()}</TooltipLabel>
          {payload.map((pld) => (
            <TooltipItem key={pld.dataKey}>
              <TooltipItemContainer>
                <TooltipIcon color={pld.dataKey === "cumulativeProfit" ? "#94E5AB" : "#B8DCEF"} />
                <TooltipText>{t(pld.dataKey)}</TooltipText>
              </TooltipItemContainer>
              <TooltipValue style={{ color: pld.dataKey === "cumulativeProfit" ? "#94E5AB" : "#B8DCEF" }}>{pld.payload[pld.dataKey === "cumulativeProfit" ? "profit" : "expenditure"].toFixed(2)}€</TooltipValue>
            </TooltipItem>
          ))}
          <TooltipSeparator />
          <TooltipTotalContainer>
            <TooltipTotal>{t('totalProfit')}</TooltipTotal>
            <TooltipTotalValue style={{ color: "#94E5AB" }}>{payload.reduce((acc, pld) => acc + (pld.dataKey === "cumulativeProfit" ? pld.payload.cumulativeProfit : 0), 0).toFixed(2)}€</TooltipTotalValue>
          </TooltipTotalContainer>
          <TooltipTotalContainer>
            <TooltipTotal>{t('totalExpenditure')}</TooltipTotal>
            <TooltipValue>{payload.reduce((acc, pld) => acc + (pld.dataKey === "cumulativeExpenditure" ? pld.payload.cumulativeExpenditure : 0), 0).toFixed(2)}€</TooltipValue>
          </TooltipTotalContainer>
        </TooltipContainer>
      );
    }

    return null;
  };

  // Update the getDateIndex function to use consistent date handling
  const getDateIndex = useCallback((date) => {
    if (!date || completeChartData.length === 0) return undefined;
    // Adjust the provided date to noon
    const target = new Date(date);
    target.setHours(12, 0, 0, 0);
    
    const index = completeChartData.findIndex(item => {
      // Parse the date parts from the YYYY-MM-DD string
      const [year, month, day] = item.date.split('-').map(Number);
      const itemDate = new Date(year, month - 1, day);
      itemDate.setHours(12, 0, 0, 0);
      return itemDate.getTime() >= target.getTime();
    });
    
    return index === -1 ? completeChartData.length - 1 : index;
  }, [completeChartData]);

  // Update the handleDateFilter function to use consistent date handling
  const handleDateFilter = (filter) => {
    setActiveFilter(filter);
    const today = new Date();
    today.setHours(12, 0, 0, 0);
    
    let newStartDate, newEndDate;

    switch (filter) {
      case '1d':
        newStartDate = new Date(today);
        newStartDate.setDate(today.getDate() - 1);
        newEndDate = new Date(today);
        break;
      case '7d':
        newStartDate = new Date(today);
        newStartDate.setDate(today.getDate() - 7);
        newEndDate = new Date(today);
        break;
      case '1m':
        newStartDate = new Date(today);
        newStartDate.setMonth(today.getMonth() - 1);
        newEndDate = new Date(today);
        break;
      case '3m':
        newStartDate = new Date(today);
        newStartDate.setMonth(today.getMonth() - 3);
        newEndDate = new Date(today);
        break;
      case '1y':
        newStartDate = new Date(today);
        newStartDate.setFullYear(today.getFullYear() - 1);
        newEndDate = new Date(today);
        break;
      case 'all':
      default:
        newStartDate = null;
        newEndDate = null;
        break;
    }

    if (newStartDate) newStartDate.setHours(12, 0, 0, 0);
    if (newEndDate) newEndDate.setHours(12, 0, 0, 0);

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  useEffect(() => {
    if (filteredChartData.length > 0) {
      const totalDays = filteredChartData.length;
      const totalProfit = filteredChartData.reduce((acc, day) => acc + day.profit, 0);
      setAverageProfitPerDay(totalProfit / totalDays);
    } else {
      setAverageProfitPerDay(0);
    }
  }, [filteredChartData]);

  return (
    <PageContainer>
      <NavBarMain currentPage="bot" type="stats" history={history} />
      {bookieData && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
        <Subcontainer>
          <HomeHeaderContainer>
            <UserAlertBadge className="alert" bgColor={"black"}>
              <i className="fas fa-user" /> {t('users')} <b>{users?.length}</b>
            </UserAlertBadge>
            <UserContainer>
              <Form.Label style={{ marginBottom: 0 }}><i className="fa-solid fa-user" /></Form.Label>
              <Form.Control
                as="select"
                label="Users"
                name="users"
                onChange={({ target }) => setSelectedUser(target.value)}
                value={selectedUser}
                style={{ display: "inline", width: "200px", height: 33.25, marginBottom: 0 }}
              >
                <option value="all">{t('allUsers')}</option>
                {users?.map(user => (
                  <option key={user._id.email} value={user._id.email}>{user._id.email}</option>
                ))}
              </Form.Control>
            </UserContainer>
            <DateRangeContainer>
              <Calendar size={20} style={{ marginLeft: 5 }}/>
              <DateFilterContainer>
                <DateFilterButton active={activeFilter === '1d'} onClick={() => handleDateFilter('1d')}>1D</DateFilterButton>
                <DateFilterButton active={activeFilter === '7d'} onClick={() => handleDateFilter('7d')}>7D</DateFilterButton>
                <DateFilterButton active={activeFilter === '1m'} onClick={() => handleDateFilter('1m')}>1M</DateFilterButton>
                <DateFilterButton active={activeFilter === '3m'} onClick={() => handleDateFilter('3m')}>3M</DateFilterButton>
                <DateFilterButton active={activeFilter === '1y'} onClick={() => handleDateFilter('1y')}>1Y</DateFilterButton>
                <DateFilterButton active={activeFilter === 'all'} onClick={() => handleDateFilter('all')}>All</DateFilterButton>
              </DateFilterContainer>
              <DatePickerWrapper>
                <DatePicker
                  selected={startDate}
                  onChange={date => {
                    setStartDate(date);
                    setActiveFilter('custom');
                  }}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText={t('startDate')}
                  calendarStartDay={1}
                  dateFormat="dd/MM/yyyy"
                />
              </DatePickerWrapper>
              <DatePickerWrapper>
                <DatePicker
                  selected={endDate}
                  onChange={date => {
                    setEndDate(date);
                    setActiveFilter('custom');
                  }}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText={t('endDate')}
                  calendarStartDay={1}
                  dateFormat="dd/MM/yyyy"
                />
              </DatePickerWrapper>
            </DateRangeContainer>
          </HomeHeaderContainer>

          {/* Add Tag Filter Section */}
          {globalSuggestedTags.length > 0 && (
            <HomeHeaderContainer className="flex flex-wrap gap-2 items-center sm:pb-4">
              {globalSuggestedTags.map((tag) => (
                <button
                  key={tag.name}
                  onClick={() => {
                    if (selectedTags.includes(tag.name)) {
                      setSelectedTags(selectedTags.filter(t => t !== tag.name));
                    } else {
                      setSelectedTags([...selectedTags, tag.name]);
                    }
                  }}
                  style={{ 
                    backgroundColor: selectedTags.includes(tag.name) ? tag.color : '#3A4C39',
                    color: selectedTags.includes(tag.name) ? getContrastColor(tag.color) : '#ABCDDF'
                  }}
                  className="px-3 py-1 rounded-full text-sm font-medium hover:opacity-80 transition-all duration-200 flex items-center gap-1"
                >
                  {tag.name}
                  {selectedTags.includes(tag.name) && (
                    <XCircleIcon size={14} className="ml-1" />
                  )}
                </button>
              ))}
            </HomeHeaderContainer>
          )}

          <DataMainContainer>
            <DataContainer>
              <DataContainerHeader>
                <DataContainerTitle>{t('profit')}</DataContainerTitle>
                <DataContainerIcon style={{ color: "#94E5AB" }}>
                  <ArrowBigUp size={34}/>
                </DataContainerIcon>
              </DataContainerHeader>
              <Value style={{ color: "#94E5AB" }}>{formatNumber(totalProfit.toFixed(0))}€</Value>
            </DataContainer>
            <DataContainer>
              <DataContainerHeader>
                <DataContainerTitle>{t('averageProfitPerDay')}</DataContainerTitle>
                <DataContainerIcon style={{ color: "#FFD700" }}>
                  <Banknote size={34}/>
                </DataContainerIcon>
              </DataContainerHeader>
              <Value style={{ color: "#FFD700" }}>{formatNumber(averageProfitPerDay.toFixed(2))}€</Value>
            </DataContainer>
            <DataContainer>
              <DataContainerHeader>
                <DataContainerTitle>{t('totalBets')}</DataContainerTitle>
                <DataContainerIcon style={{ color: "#E9FD8C" }}>
                  <Sigma size={34}/>
                </DataContainerIcon>
              </DataContainerHeader>
              <Value style={{ color: "#E9FD8C" }}>{formatNumber(totalBets)}</Value>
            </DataContainer>
            <DataContainer>
              <DataContainerHeader>
                <DataContainerTitle>{t('expenditure')}</DataContainerTitle>
                <DataContainerIcon style={{ color: "#B8DCEF" }}>
                  <PiggyBank size={34} />
                </DataContainerIcon>
              </DataContainerHeader>
              <Value style={{ color: "#B8DCEF" }}>{formatNumber(Math.abs(totalExpenditure.toFixed(0)))}€</Value>
            </DataContainer>
            <DataContainer>
              <DataContainerHeader>
                <DataContainerTitle>Yield</DataContainerTitle>
                <DataContainerIcon style={{ color: "#C084FC" }}>
                  <Percent size={34}/>
                </DataContainerIcon>
              </DataContainerHeader>
              <Value style={{ color: "#C084FC" }}>{formatNumber(totalYield.toFixed(2))}%</Value>
            </DataContainer>
          </DataMainContainer>
          <ChartContainerParent>
            <ChartContainer>
              <ChartContainerHeader>
                <Title>{t('profit')}</Title>
              </ChartContainerHeader>
              <ResponsiveContainer width="100%" height={550}>
                <LineChart data={completeChartData} margin={{ top: 20, bottom: 70 }}>
                  <CartesianGrid strokeDasharray="3 0" vertical={false} stroke="rgba(255, 255, 255, 0.1)"/>
                  <XAxis 
                    dataKey="date" 
                    stroke="white" 
                    axisLine={false} 
                    tickLine={false} 
                    tickMargin={5} 
                    angle={-45} 
                    textAnchor="end"
                    tickFormatter={(date) => {
                      const [, month, day] = date.split('-');
                      return `${day}/${month}`;
                    }}
                    interval="preserveStartEnd"
                    minTickGap={40}
                    height={60}
                  />
                  <YAxis yAxisId="left" stroke="white" axisLine={false} tickLine={false} tickMargin={5} />
                  <YAxis yAxisId="right" orientation="right" stroke="white" axisLine={false} tickLine={false} tickMargin={5} />
                  <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
                  <Line yAxisId="left" type="monotone" dataKey="cumulativeProfit" name="Cumulative Profit" stroke="#94E5AB" dot={false} strokeWidth={3} />
                  <Line yAxisId="right" type="monotone" dataKey="cumulativeExpenditure" name="Cumulative Expenditure" stroke="#B8DCEF" dot={false} strokeWidth={3} />
                  <Brush
                    dataKey="date"
                    height={40}
                    stroke="#758b77"
                    fill="#192619"
                    onChange={handleBrushChange}
                    startIndex={getDateIndex(startDate)}
                    endIndex={getDateIndex(endDate)}
                    y={470}
                    tickFormatter={(date) => {
                      const [year, month, day] = date.split('-');
                      return new Date(year, month - 1, day).toLocaleDateString();
                    }}
                  >
                    <AreaChart>
                      <CartesianGrid strokeDasharray="3 0" vertical={false} stroke="rgba(255, 255, 255, 0.1)"/>
                      <YAxis hide domain={['auto', 'auto']} />
                      <Area dataKey="cumulativeProfit" stroke="#94E5AB" fill="#94E5AB" dot={false} />
                    </AreaChart>
                  </Brush>
                </LineChart>
              </ResponsiveContainer>
            </ChartContainer>
          </ChartContainerParent>
          <ChartContainer style={{ overflow: 'scroll', width: '100%' }}>
            <ChartContainerHeader>
              <Title>Bookies</Title>
            </ChartContainerHeader>
            <Table hover>
              <thead>
                <tr>
                  <th>Bookie</th>
                  <th>{t('profit')}</th>
                  <th>{t('totalPending')}</th>
                  <th>{t('expenditure')}</th>
                  <th>{t('totalBets')}</th>
                </tr>
              </thead>
              <tbody>
                {bookieData
                  ?.sort((a, b) => b.totalBets - a.totalBets)
                  .map((bookie, index) => (
                    <tr key={index} style={{ backgroundColor: index % 2 ? '#111E12' : '#1d2d1d' }}>
                      <td>{bookie.name}</td>
                      <td>{formatNumber(bookie.profit.toFixed(2))}€</td>
                      <td>{formatNumber(bookie.pending.toFixed(2))}€</td>
                      <td>{formatNumber(bookie.expenditure.toFixed(2))}€</td>
                      <td>{bookie.totalBets}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </ChartContainer>
        </Subcontainer>
      </motion.div> 
      )}
    </PageContainer>
  );
};