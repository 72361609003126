import styled from "styled-components";

export const PageContainer = styled.div`
  width: 100%;
  padding: 20px 50px 50px 50px;
  font-family: 'Montserrat';

  @media only screen and (max-width: 700px) {
    padding: 20px 10px 10px 10px;
  }

  .customAlert > p {
    margin-bottom: 0;
  }

`;

export const Subcontainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  width: 100%;
  margin: auto;
  margin-top: 20px;
  gap: 20px;

  @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
`;

export const HomeHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin: 1rem 0 0 0;
  
  &.filter-container {
    @media only screen and (max-width: 700px) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  @media only screen and (max-width: 700px) {
    padding-left: 0px;
    margin: 0.5rem 0;
  }
  
  .card-grid-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    
    @media only screen and (max-width: 700px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
    }
  }
`

export const UserAlertBadge = styled.div`
  background: #192619;
  margin: 0px;
  padding: 8px 12px;
  border-radius: 8px;
  min-width: 120px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  transition: all 0.2s ease;

  &.flex-row {
    flex-direction: row;
    align-items: center;
    min-width: 300px;
    @media only screen and (max-width: 768px) {
      min-width: 100%;
      flex-wrap: wrap;
      gap: 8px;
    }
    
    @media only screen and (max-width: 700px) {
      flex-direction: row;
      align-items: center;
    }
  }
  
  &.card-grid-item {
    @media only screen and (max-width: 700px) {
      min-width: unset;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .badge-label {
    font-size: 0.9rem;
    color: #758B78;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 6px;
    
    i {
      font-size: 1rem;
      color: #ABCDDF;
    }
  }

  .badge-value {
    font-size: 1.1rem;
    color: white;
    font-weight: 600;
    text-align: left;
  }

  @media only screen and (max-width: 700px) {
    min-width: 100px;
    padding: 6px 10px;

    .badge-label {
      font-size: 0.75rem;
    }

    .badge-value {
      font-size: 1rem;
    }
  }
`

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media only screen and (max-width: 700px) {
    width: 100%;
    padding: 0px;
    margin-top: 5px;
  }
`

export const MoneyChangeBadge = styled.span`
  padding: 3px 5px;
  font-size: 0.8rem;
  margin-left: 5px;
  background-color: ${props => props.pendingPayments >= 0 ? "#94E5AB" : "#A33"};
  color: ${props => props.pendingPayments >= 0 ? "#1C2D1C" : "white"};
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  
  @media only screen and (max-width: 700px) {
    font-size: 0.75rem;
    padding: 2px 4px;
    margin-left: 3px;
  }
`

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #192619;
  padding: 4.5px;
  border-radius: 5px;
  padding-right: 10px;
`

export const NameSearchContainer = styled.div`
  width: 200px;

  input {
    background-color: #192619;
    border: 0px;
    color: white;

    ::placeholder {
      color: white;
      opacity: 0.6;
    }
  }
`;

export const WarningBanner = styled.div`
  background-color: #fff3cd;
  color: #856404;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.375rem;
  border: 1px solid #ffeeba;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    transition: all 0.2s;
    font-weight: 500;
    
    &:first-of-type {
      color: #2563eb;
      text-decoration: underline;
      
      &:hover {
        color: #1d4ed8;
      }
    }
    
    &:last-of-type {
      background-color: #16a34a;
      color: white;
      padding: 0.25rem 1rem;
      border-radius: 0.375rem;
      
      &:hover {
        background-color: #15803d;
      }
    }
  }

  .flex {
    display: flex;
  }

  .items-center {
    align-items: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .w-full {
    width: 100%;
  }

  .gap-4 {
    gap: 1rem;
  }

  .mr-2 {
    margin-right: 0.5rem;
  }
`;

export const LoadingLine = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #E8FC8B, #4CAF50);
  z-index: 9999;
  animation: loading 2s ease-in-out forwards;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  @keyframes loading {
    0% {
      transform: scaleX(0);
      opacity: 1;
    }
    50% {
      transform: scaleX(1);
      opacity: 1;
    }
    100% {
      transform: scaleX(0);
      opacity: 0;
    }
  }
`;

export const HeaderCardsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  & .user-card-group {
    flex: 0 0 auto;
  }

  @media only screen and (min-width: 700px) and (max-width: 1300px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    gap: 10px;

    & .user-card-group {
      width: 100%;
    }
  }
`;

export const CardsGroup = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  gap: 10px;

  > * {
    flex: 1;
  }

  @media only screen and (min-width: 700px) and (max-width: 1300px) {
    width: 100%;
  }

  @media only screen and (max-width: 700px) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
`;