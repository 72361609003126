import React, { useState } from "react";
import ReactHtmlParser from "html-react-parser";
import {
    AddBookieButtonContainer, AddBookieContainer, AddBookieContainerButtons, BookieCard, BookieInformation,
    BookieName, BookieNameBadge, BookieNameBadgesContainer, BookieNameContainer, BookieWarning, ActionButton
} from './Bookie.styled'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import BookieModal from './BookieModal';

const Bookie = ({ bookie, type = "add", toggleBookieStatus, match, activeBookies, bookies, onRefresh }) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const handleAddBookie = () => {
        setShowModal(true);
    };

    const getUserEnabled = (bookieName) =>
        Object.prototype.hasOwnProperty.call(activeBookies, bookieName)
            ? activeBookies[bookieName]
            : bookies.find((bookie) => bookie.name === bookieName).userEnabled;

    const getEmail = () => match.params.email;

    return (
        <>
            <BookieCard>
                <AddBookieContainer>
                    <BookieNameContainer>
                        <BookieNameBadgesContainer>
                            {bookie.mandatory && (
                                <BookieNameBadge className="badge-warning">{t("mandatory")}</BookieNameBadge>
                            )}
                            {bookie.provider && (
                                <BookieNameBadge className="provider">{bookie.provider}</BookieNameBadge>
                            )}
                        </BookieNameBadgesContainer>
                        <BookieName>{bookie.name}</BookieName>
                    </BookieNameContainer>
                    <BookieInformation>
                        {bookie.warning && (
                            <BookieWarning>
                                {bookie.warning}
                            </BookieWarning>
                        )}

                        <h4>{t("registerAndDeposit")}</h4>
                        {bookie?.information?.register.validation && (
                            <p>
                                <i className="far fa-clock"/>{" "}
                                {ReactHtmlParser(bookie.information.register.validation)}
                            </p>
                        )}
                        {bookie?.information?.register.deposit && (
                            <p>
                                <i className="fas fa-coins"/>{" "}
                                {ReactHtmlParser(bookie.information.register.deposit)}
                            </p>
                        )}
                        {(bookie?.information?.recommendations || []).map((recommendation, index) => (
                            <p key={index}>
                                <a>{ReactHtmlParser(recommendation)}</a>
                            </p>
                        ))}
                    </BookieInformation>
                    <BookieInformation>
                        <h4>{t("withdraw")}</h4>
                        {bookie?.information?.withdraw.waiting && (
                            <p>
                                <i className="far fa-clock"/>{" "}
                                {ReactHtmlParser(bookie?.information?.withdraw.waiting)}
                            </p>
                        )}
                        {bookie?.information?.withdraw.phone && (
                            <p>
                                <i className="fas fa-phone-alt"/>{" "}
                                {ReactHtmlParser(bookie?.information?.withdraw.phone)}
                            </p>
                        )}
                    </BookieInformation>
                </AddBookieContainer>
                {getEmail() && (
                    <AddBookieButtonContainer>
                        <AddBookieContainerButtons>
                            <ActionButton className="addBookie" onClick={handleAddBookie}>
                                {type === "add" ? t("add") : t("edit")}
                            </ActionButton>
                            {type === "edit" && (
                                <ActionButton
                                    className="enableBookie"
                                    style={{ 
                                        backgroundColor: getUserEnabled(bookie.name) ? "#A33" : "#94E5AB", 
                                        color: getUserEnabled(bookie.name) ? "white" : "#1C2D1C"
                                    }}
                                    onClick={() => toggleBookieStatus(bookie.name, getUserEnabled(bookie.name))}
                                >
                                    {getUserEnabled(bookie.name) ? t("disable") : t("enable")}
                                </ActionButton>
                            )}
                        </AddBookieContainerButtons>
                    </AddBookieButtonContainer>
                )}
            </BookieCard>
            <BookieModal 
                show={showModal}
                onHide={() => setShowModal(false)}
                bookie={bookie.name}
                type={type}
                email={getEmail()}
                onSuccess={onRefresh}
            />
        </>
    );
}

export default withRouter(Bookie)
